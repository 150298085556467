import React, { createContext, useReducer, useEffect } from "react"
import { loadState, saveState } from "./localStorage"
import throttle from "lodash/throttle"

const initialState = {
  user: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return Object.assign({}, state, {
        user: action.user,
      })
    case "LOGOUT":
      return Object.assign({}, state, {
        user: null,
      })
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.products,
      }
    default:
      return state
  }
}

const StateContext = createContext()
const DispatchContext = createContext()
const localState = loadState()

const StateProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(reducer, initialState)
  const [state, dispatch] = useReducer(reducer, localState || initialState)
  useEffect(
    throttle(() => {
      saveState(state)
    }, 1000),
    [state]
  )
  // useEffect(() => {
  //   console.log("checking if user is logged in")
  //   axios
  //     .get(URL, {
  //       headers: {
  //         "Conent-type": "application/json",
  //         Authorization: `Bearer ${state.token}`,
  //       },
  //     })
  //     .then(res => {
  //       console.log(res)
  //       const user = res.data
  //       dispatch({ type: "SET_USER", user: user })
  //     })
  //     .catch(e => console.log(e))
  // },[])
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useGlobalState = () => {
  const state = React.useContext(StateContext)
  if (state === undefined) {
    throw new Error("useGlobalState must be used within a StateProvider")
  }
  return state
}

const useGlobalDispatch = () => {
  const dispatch = React.useContext(DispatchContext)
  if (dispatch === undefined) {
    throw new Error("useGlobalState must be used within a StateProvider")
  }
  return dispatch
}

export { StateProvider, useGlobalState, useGlobalDispatch }
