/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import "./src/styles/nprogress.css"
import React from "react"
import { StateProvider } from "./src/utilities/globalContext"
export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
)

//Necessary to fix backward navigation issue
export const onInitialClientRender = () => {
  window.addEventListener(
    "popstate",
    () => (window.location.href = window.location.href)
  )
}
